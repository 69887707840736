import * as Yup from 'yup';

const regexForValidationOfAlphabetsAndSpace = /^[A-Za-z\s]+$/;
const regexForValidationOfAlphaNumericAndSpace = /^[A-Z0-9\s]*$/;
const regexForValidationOfAlphaNumeric = /^[A-Za-z0-9]*$/;

const requiredValidator = (message: string) => {
  return Yup.string().required(message);
};

const nameValidator = (message: string) => {
  return Yup.string().matches(regexForValidationOfAlphabetsAndSpace, message);
};

const nameLengthValidator = (message: string) => {
  return Yup.string().min(4, message);
};

const nameValidation = Yup.string()
  .required('Name is required.')
  .matches(
    regexForValidationOfAlphabetsAndSpace,
    'Please provide a valid name.'
  )
  .min(4, 'Name must be atleast 4 characters.')
  .max(40, 'Name must be maximum of 40 characters.')
  .test(
    'both-names',
    'Please provide both first name and last name.',
    value => {
      if (!value) return true;
      const parts = value.trim().split(/\s{1,}/);
      return parts.length > 1 && parts.every(part => part.length > 0);
    }
  );

const VehicleValidationSchema = Yup.object({
  number: Yup.string()
    .required('Vehicle number is required.')
    .matches(
      regexForValidationOfAlphaNumericAndSpace,
      'Allowed characters are capital alphabets, numbers, and space.'
    )
    .trim()
    .min(4, 'Vehicle number must be atleast 4 characters.')
    .max(20, 'Vehicle number must be maximum of 20 characters.'),
  name: Yup.string()
    .required('Vehicle name is required.')
    .trim()
    .matches(
      /^[A-Za-z0-9\s]*$/,
      'Allowed characters are alphabets, numbers, and space.'
    )
    .min(4, 'Name must be atleast 4 characters.'),
  vehicle_tonnage: Yup.string()
    .trim()
    .matches(
      /^\d{1,18}(\.\d{1,2})?$/, 
      'Vehicle tonnage must be a number with up to 2 decimal places.'
    )
    .min(1, 'Vehicle tonnage must be atleast 1 character.'),
  transporter: Yup.mixed().required('Transporter is required.'),
});

const transporterValidationSchema = Yup.object({
  name: nameValidation,
  code: Yup.string()
    .required('Transporter code is required.')
    .matches(
      regexForValidationOfAlphaNumeric,
      'Allowed characters are alphabets and numbers.'
    )
    .min(1, 'Code must be atleast 1 characters.')
    .max(20, 'Code must be maximum of 20 characters.'),
  address: Yup.string()
    .trim()
    .max(250, 'Address must be maximum of 250 characters.'),
  phone: Yup.string()
    .required('Transporter phone is required.')
    .matches(/^\d{10}$/, 'Please provide a valid phone number.'),
});

const removeExtraSpace = (value: string) => {
  return value?.trim().replace(/\s+/g, ' ');
};

const vehicleOperatorSchema = Yup.object({
  name: nameValidation,
  email: Yup.string().email('Please provide a valid email address.'),
  phone: Yup.string().required('Phone number is required.').matches(
    /^\d{10}$/,
    'Please provide a valid phone number.'
  ),
  licence_no: Yup.string()
    .trim()
    .max(16, 'Licence number must be maximum of 16 characters.'),
  aadhar_no: Yup.string()
    .matches(
      /^\d{12}$/,
      'Please provide a valid aadhar number.'
    ),
  date_of_issue: Yup.date(),
  validity_date: Yup.date(),
  badge_no: Yup.string()
    .trim()
    .max(16, 'Badge number must be maximum of 16 characters.'),
});

const deliveryValidationSchema = Yup.object({
  invoice_number: Yup.string()
    .matches(
      /^[a-zA-Z0-9,()-]*$/,
      'Allowed characters are alphabet, number and comma'
    )
    .required('Invoice Number is required')
    .max(250, 'Invoice Number cannot exceed 250 characters'),
  total_cases: Yup.number()
    .typeError('No.of Cases must be a number')
    .required('No.of Cases is required')
    .min(1, 'No.of Cases must be atleast 1')
    .max(32766, 'No.of Cases cannot exceed 32766'),
  notes: Yup.string()
    .matches(
      /^[a-zA-Z\s]*$/,
      'Allowed characters are alphabet and space (only in between)'
    )
    .nullable(),
});

const validationSchemaForSettings = {
  code: Yup.string()
    .required('Code is required.')
    .matches(
      regexForValidationOfAlphaNumeric,
      'Allowed characters are alphabet and number'
    )
    .trim()
    .min(1, 'Code must contain atleast 1 character.')
    .max(10, 'Code cannot exceed 10 characters.'),
  name: Yup.string()
    .required('Name is required.')
    .matches(
      /^[A-Za-z0-9\s]*$/,
      'Allowed characters are alphabets, numbers and space'
    )
    .trim()
    .min(1, 'Name must contain atleast 1 character.')
    .max(50, 'Name cannot exceed 50 characters.'),
  notes: Yup.string()
    .optional()
    .matches(
      /^[A-Za-z0-9\s]*$/,
      'Allowed characters are alphabets, numbers and space'
    )
    .trim()
    .min(1, 'Notes must contain atleast 1 character.'),
};

const customerValidationSchema = Yup.object({
  code: Yup.string()
    .required('Code is required.')
    .matches(
      regexForValidationOfAlphaNumeric,
      'Allowed characters are alphabet and number'
    )
    .trim()
    .min(1, 'Code must contain atleast 1 character.')
    .max(10, 'Code cannot exceed 10 characters.'),
  name: nameValidation,
  shipping_address: Yup.string()
    .required('Shippping Location is required.')
    .trim()
    .max(250, 'Address must be maximum of 250 characters.'),
  gst_number: Yup.string()
    .matches(
      regexForValidationOfAlphaNumeric,
      'Allowed characters are alphabet and number'
    )
    .trim()
    .min(15, 'GST number must be minimum of 15 characters.')
    .max(15, 'GST number must be maximum of 15 characters.'),
  phone: Yup.string()
    .required('Phone is required.')
    .matches(/^\d{10}$/, 'Please provide a valid phone number.'),
  lead_days: Yup.number()
    .typeError('Lead days must be a number')
    .max(32766, 'Lead days cannot exceed 32766'),
});

const WarehouseValidationSchema = Yup.object({
  warehouse_code: validationSchemaForSettings.code,
  warehouse_name: validationSchemaForSettings.name,
});
const LocationValidationSchema = Yup.object({
  location_code: validationSchemaForSettings.code,
  location_name: validationSchemaForSettings.name,
  notes: validationSchemaForSettings.notes,
});
const ZoneValidationSchema = Yup.object({
  zone_code: validationSchemaForSettings.code,
  zone_name: validationSchemaForSettings.name,
  notes: validationSchemaForSettings.notes,
});


export {
  requiredValidator,
  nameValidator,
  removeExtraSpace,
  regexForValidationOfAlphabetsAndSpace,
  regexForValidationOfAlphaNumericAndSpace,
  VehicleValidationSchema,
  nameLengthValidator,
  transporterValidationSchema,
  vehicleOperatorSchema,
  deliveryValidationSchema,
  WarehouseValidationSchema,
  LocationValidationSchema,
  ZoneValidationSchema,
  customerValidationSchema,
};
