import './SideNavigationBar.scss';
import { BuildingIcon, PeopleIcon, DeliveryIcon, SettingsIcon } from '../icon';
import NavItem from '../elements/NavItem';

function SideNavigationBar() {
  return (
    <div className="nav-sidebar">
      <NavItem
        to="/delivery"
        label='Deliveries'
        icon={<DeliveryIcon />}
      />
      <NavItem
        label='Settings'
        icon={<SettingsIcon />}
        subRoutes={[
          { to: '/settings/customers', label: 'Customers', icon: <PeopleIcon /> },
          { to: '/settings/warehouses', label: 'Warehouses', icon: <BuildingIcon /> },
        ]}
      />
    </div>
  );
}

export default SideNavigationBar;
